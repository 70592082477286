import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import styled from "styled-components";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const BigNo = ({
  no,
  children
}) => <div css={`
        display: flex;
    `}>
    <div css={`
            flex: 0 0 6rem;
            margin-right: ${props => props.theme.spacings.normal};
        `}>
        <span css={`
                display: inline-block;
                width: 4.5rem;
                height: 4.5rem;
                font-size: 3rem;
                font-weight: bold;
                border: ${props => props.theme.dimensions.lineThickness} solid;
                border-radius: 99px;
                text-align: center;
                color: ${props => props.theme.colors.decorative};
            `}>
            {no}
        </span>
    </div>
    <div css={`
            flex: 1 1;
        `}>
        {children}
    </div>
</div>;
export const _frontmatter = {};
const layoutProps = {
  BigNo,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Getting Started with TuringTrader`}</h1>
    <Breadcrumbs to="/help/" text="Help" mdxType="Breadcrumbs" />
    <p>{`Investing with `}<em parentName="p">{`TuringTrader`}</em>{` is really easy, and you will get the hang of it in no time. Here are the essential steps to hit the ground running:`}</p>
    <br />
    <BigNo no="1" mdxType="BigNo">
      <h2>{`Select Your Portfolios`}</h2>
      <p><em parentName="p">{`TuringTrader`}</em>{` offers a large number of portfolios to choose from, addressing a wide array of investment objectives. This amount of options can quickly become overwhelming. `}<a parentName="p" {...{
          "href": "/help/find-your-portfolio/"
        }}>{`Read here`}</a>{` to learn how to find a portfolio that fits your style.`}</p>
    </BigNo>
    <BigNo no="2" mdxType="BigNo">
      <h2>{`Configure Your Dashboard`}</h2>
      <p><em parentName="p">{`TuringTrader`}</em>{`'s dashboard is the site's central hub. It provides a convenient overview of all your investment accounts, and it shows which of these accounts need your attention. Learn how to configure your dashboard and monitor your investments in `}<a parentName="p" {...{
          "href": "/help/investment-dashboard/"
        }}>{`this article`}</a>{`.`}</p>
    </BigNo>
    <BigNo no="3" mdxType="BigNo">
      <h2>{`Rebalance Your Accounts`}</h2>
      <p>{`Rebalancing is where the rubber hits the road: the process of checking your account and submitting the orders to match your portfolio's suggested asset allocation. `}<a parentName="p" {...{
          "href": "/help/account-rebalancing/"
        }}>{`Read this article`}</a>{` to learn the simple steps.`}</p>
    </BigNo>
    <BigNo no="4" mdxType="BigNo">
      <h2>{`Habits for Success`}</h2>
      <p>{`Investing with `}<em parentName="p">{`TuringTrader`}</em>{` is easy. But your long-term success depends on adopting some new habits. `}<a parentName="p" {...{
          "href": "/help/habits-for-success/"
        }}>{`Read here`}</a>{` to learn what to watch out for.`}</p>
    </BigNo>
    <br /><br /><br />
    <ButtonPrimary text="explore our portfolios" to="/portfolios/" mdxType="ButtonPrimary" />
&nbsp;&nbsp;&nbsp;&nbsp;
    <ButtonSecondary text="learn how to use our site" to="/help/" mdxType="ButtonSecondary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      